<template>
  <div class="eleven-prize-list-2019" v-no-rubberband>
    <div class="g-content">
      <div class="real-tab-item">
        <ul class="real-list">
          <li class="real-item" v-for="(item, index) in realList" :key="item.id">
            <span class="name">{{ item.prize.name }}</span>
            <span
              class="operate with-addr"
              v-if="item.address&&item.prize.type==1"
              @click="onViewAddress(item, index, realList)"
            >查看地址</span>
            <span
              class="operate without-addr"
              v-if="!item.address&&item.prize.type==1"
              @click="onFillAddress(item, index, realList)"
            >填写地址</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 弹窗：查看地址 -->
    <mt-popup class="address-popup" popup-transition="popup-fade" v-model="addressPopup.show">
      <p class="title">收货信息</p>
      <div class="content">
        <p class="username">
          收货人：
          <span class="value">{{ addressPopup.address.name }}</span>
        </p>
        <p class="phone">
          手机号码：
          <span class="value">{{ addressPopup.address.phone }}</span>
        </p>
        <p class="address">
          收货地址：
          <span class="value">{{ addressPopup.address.fullAddress }}</span>
        </p>
      </div>
      <div class="btn-confirm btn-shadow" @click="addressPopup.show = false">
        <span>确定</span>
      </div>
    </mt-popup>
    <div v-if="!realList" class="nodata">没有中奖记录</div>
    <choose-pocket-popup ref="refChoosePocketPopup" @confirm="onConfirmPopup" />
  </div>
</template>

<script>
import ChoosePocketPopup from "../components/ChoosePocketPopup.vue";
import { PRIZE_TYPE } from "@/pages/eleven2019/constant/lottery";
import { get } from "lodash";
import moment from "moment";

export default {
  name: "eleven_prize_list_2019",
  components: {
    ChoosePocketPopup
  },
  data() {
    return {
      PRIZE_TYPE,
      type: PRIZE_TYPE.GOODS,
      addressPopup: {
        show: false,
        address: {}
      },
      realList: [], // 实物奖品数组
      couponList: [] // 现金券奖品数组
    };
  },
  methods: {
    /**
     * [事件]弹窗确定后：刷新页面
     */
    async onConfirmPopup() {
      await this.onSelectTab(this.type);
    },
    /**
     * [网络请求]获取奖品列表
     * @param {number} type 奖品类型
     * @returns {array} 奖品列表
     */
    async getList() {
      const res = await this.$http1.get(`/elevenact/myprize`, {
        activityId: this.$route.query.activityId,
        userId: sessionStorage.getItem("userId")
      });
      this.realList = res;
      this.realList = res.length == 0 ? null : res;
      // 处理返回参数
    },
    /**
     * 辅助方法：打开弹窗[地址信息]
     */
    showAddressPopup(address) {
      this.addressPopup = {
        show: true,
        address
      };
    },
    /**
     * 事件：查看地址
     */
    onViewAddress(item, index, realList) {
      this.showAddressPopup(item.address);
    },
    /**
     * 事件：选择地址
     */
    onFillAddress(item, index, realList) {
      this.$router.push({
        name: "address-list",
        query: {
          from: "eleven_prize_list_2019",
          record: item.id
        }
      });
    }
  },
  created() {
    this.getList();
  },
  beforeRouteLeave(to, from, next) {
    const refChoosePocketPopup = this.$refs.refChoosePocketPopup;
    if (refChoosePocketPopup.isShowing()) {
      refChoosePocketPopup.closePopup();
      next(false);
      return;
    }
    next();
  }
};
</script>

<style lang="less" scoped>
@import "../less/variable.less";
@import "../less/icon.less";

// 按钮阴影效果
.btn-shadow {
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);
}

.eleven-prize-list-2019 {
  background: white;
  font-family: PingFangSC-Regular;
  user-select: none;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  // 标签
  .g-tabs {
    position: fixed;
    z-index: 1;
    background: white;
    top: 0;
    left: 0;
    right: 0;
    display: flex;

    span {
      width: 0;
      flex: 1;
      font-size: 14px;
      line-height: 20px;
      color: #3b3b3b;
      letter-spacing: 1px;
      padding: 16px 0;
      text-align: center;
      border-bottom: 1px solid #f4f4f4;

      &.active {
        font-weight: bold;
        border-bottom-color: #f8d168;
      }
    }
  }

  // 内容(列表)
  .g-content {
    .real-tab-item {
      ul.real-list {
        li.real-item {
          border-bottom: 1px solid #eee;
          box-sizing: border-box;
          height: 50px;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px;
          letter-spacing: 1px;

          .operate {
            color: #3b3b3b;
            border: 1px solid #3b3b3b;
            border-radius: 15.5px;
            padding: 5px 10px;

            &.without-addr {
              color: #f8d168;
              border-color: #f8d168;
            }
          }
        }
      }
    }

    .coupon-tab-item {
      .coupon-list {
        padding: 8px 0;

        .coupon-item {
          margin: 12px;
          padding: 28px 21px 27px;
          display: flex;
          align-items: center;
          background-image: url("../../../assets/images/eleven2019/coupon-border.png");
          background-origin: padding-box;
          background-size: 100% 100%;

          .text {
            flex-grow: 1;

            .name {
              font-size: 14px;
              line-height: 20px;
              color: #3b3b3b;
            }

            .brand,
            .time {
              font-size: 12px;
              line-height: 17px;
              color: #7a7a7a;
              letter-spacing: 0.86px;
            }

            .brand {
              margin-top: 8px;
            }

            .time {
              margin-top: 4px;
            }
          }

          .btn-select {
            flex-shrink: 0;
            box-sizing: border-box;
            width: 60px;
            height: 30px;
            line-height: 30px;
            background: #f8d168;
            border: 1px solid #f8d168;
            border-radius: 15.5px;
            font-size: 14px;
            color: white;
            letter-spacing: 1px;
            text-align: center;
          }
        }
      }
    }
  }

  .address-popup {
    width: 75%;
    background: #ffffff;
    border-radius: 10px;

    .title {
      font-size: 14px;
      line-height: 20px;
      color: #3b3b3b;
      letter-spacing: 0.93px;
      text-align: center;
      padding: 14px 0 21px;
    }

    .content {
      font-size: 12px;
      line-height: 17px;
      color: #3b3b3b;
      letter-spacing: 0.86px;
      margin: 0 28px;

      p {
        font-weight: 600;
        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      .value {
        color: black;
        font-weight: 400;
        word-break: break-all;
      }
    }

    .btn-confirm {
      width: 140px;
      height: 40px;
      line-height: 40px;
      background: #f8d168;
      border-radius: 25px;
      margin: 29px auto 25px;
      text-align: center;

      span {
        font-size: 15px;
        line-height: 21px;
        color: #3b3b3b;
        letter-spacing: 0;
      }
    }
  }
  .nodata {
    margin-top: 40px;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }
}
</style>
