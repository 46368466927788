export const PRIZE_TYPE = {
  GOODS: 1, // 实物
  COUPON: 0 // 免单
}
// 不同的活动id对应着不通的活动的信息包括图片等
export const PRIZE_ACTIVITYID = [
  {
    id: 'a80a71f3-a5d2-403b-abc1-a556969db86a',
    dir: 'sgs',
    name: '水光生',
    prize_list: [1, 2, 6, 5, 4, 3, 7, 0]
  }, {
    id: '027c2e75-e3db-47ea-a580-03e91d8250bd',
    dir: 'bx',
    name: '白皙',
    prize_list: [2, 7, 4, 5, 6, 3, 0, 1]
  }
]