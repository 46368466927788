<template>
  <div class="iv-lottery-2018">
    <!-- 背景图 -->
    <img class="bg" src="@/assets/images/eleven2019/bg-body1.png" />
    <!-- 九宫格 -->
    <ul class="grid">
      <!-- 第1列 -->
      <li class="row">
        <div
          :class="['col-4' , {active : activeClass[index]}]"
          v-for="(prize, index) in lis1"
          :key="index"
        >
          <img class="inactive-img" :src="prize" />
          <img class="active-img" :src="win1[index]" />
        </div>
      </li>
      <!-- 第2列 -->
      <li class="row">
        <div :class="['col-4' , {active : activeClass[7]}]">
          <img class="inactive-img" :src="prizesList[7]" />
          <img class="active-img" :src="winList[7]" />
        </div>
        <div :class="['col-4', {'active' : lock}]" @click="startLottery">
          <img class="inactive-img" :src="lotteryBtn.img" />
          <img class="active-img" :src="lotteryBtn.activeImg" />
        </div>
        <div :class="['col-4' , {active : activeClass[3]}]">
          <img class="inactive-img" :src="prizesList[3]" />
          <img class="active-img" :src="winList[3]" />
        </div>
      </li>
      <!-- 第3列 -->
      <li class="row">
        <div
          :class="['col-4' , {active : activeClass[6-index]}]"
          v-for="(prize, index) in lis2"
          :key="index"
        >
          <img class="inactive-img" :src="prize" />
          <img class="active-img" :src="win2[index]" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ElevenNineGrid2018",
  data() {
    return {
      activeClass: new Array(8).fill(false),
      index: 7, // 当前格子对应的index
      timer: null, // 计数器ID，用以clearTimeout
      times: 0, // 已走步数
      speedData: 100, // 毫秒数，表示距离走下一步的时间
      lock: false // true表示正在转动
    };
  },
  props: {
    prizesList: {
      type: Array,
      required: true
    },
    winList: {
      type: Array,
      required: true
    },
    lotteryBtn: {
      type: Object,
      required: true
    },
    beforeLottery: {
      type: Function,
      required: true
    },
    prize: {
      type: Number,
      default: 0
    },
    speed: {
      type: Number,
      default: 10
    },
    cycle: {
      type: Number,
      default: 1
    }
  },
  computed: {
    lis1() {
      return this.prizesList.slice(0, 3);
    },
    lis2() {
      return this.prizesList.slice(4, 7).reverse();
    },
    win1() {
      return this.winList.slice(0, 3);
    },
    win2() {
      return this.winList.slice(4, 7).reverse();
    }
  },
  created() {
    this.speedData = this.speed;
  },
  methods: {
    /**
     * 开始抽奖
     */
    async startLottery() {
      if (!this.lock) {
        this.lock = true; // 锁定转盘
        try {
          const canStart = await this.beforeLottery();
          if (!canStart) {
            this.lock = false;
            return;
          }
          this.roll();
        } catch (e) {
          this.lock = false;
          console.log("抽奖前报错啦", e);
        }
      }
    },
    /**
     * 每走一步
     */
    roll() {
      this.times += 1;
      this.activeClass = new Array(8).fill(false);
      const nextIndex = (this.index - 1 + 8) % 8;
      this.activeClass[nextIndex] = true;
      this.index = nextIndex;

      if (this.times > this.cycle + 10 && this.prize === this.index) {
        /**
         * 结束
         */
        clearTimeout(this.timer);
        setTimeout(() => {
          this._showResult();
        }, 1000);
      } else {
        /**
         * 还要继续，则调整步速
         */
        if (this.times < this.cycle) {
          // 先变快
          this.speedData -= 2;
        } else {
          if (
            this.times > this.cycle + 10 &&
            ((this.prize === 0 && this.index === 7) ||
              this.prize === this.index - 1)
          ) {
            this.speedData += 110;
          } else {
            this.speedData += 20;
          }
        }
        if (this.speedData < 40) {
          // 保证最小速度
          this.speedData = 40;
        }
        this.timer = setTimeout(_ => {
          // 递归
          this.roll();
        }, this.speedData);
      }
      return false;
    },
    _showResult() {
      // 重置数据
      this.lock = false;
      this.index = this.prize;
      this.timer = null;
      this.speedData = this.speed;
      this.times = 0;
      // 回调
      this.$emit("after-lottery");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../less/variable.less";

.iv-lottery-2018 {
  position: relative;

  img.bg {
    padding-top: 12%;
    padding-left: 6.7%;
    width: 84%;
  }

  ul.grid {
    position: absolute;
    bottom: 1.2%;
    left: 6.7%;
    width: 84%;
    height: 84%;
    display: flex;
    flex-direction: row;

    li.row {
      width: 0;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin: 0 1px;

      div.col-4 {
        height: 0;
        flex-grow: 1;
        position: relative;
        margin: 1px 0;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .active-img {
          display: none;
        }

        &.active .active-img {
          display: block;
        }
      }
    }
  }
}
</style>
