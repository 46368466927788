<template>
  <div class="eleven_lottery_2018">
    <!-- 内容部分 -->
    <img :src="bgimg" class="bgimg" />
    <div class="g-content">
      <!-- 转盘 -->
      <eleven-nine-grid
        :beforeLottery="beforeLottery"
        @after-lottery="afterLottery"
        :lotteryBtn="lotteryBtn"
        :prizesList="orderlyPrizeList.map(item => item.thumb)"
        :winList="orderlyPrizeList.map(item => item.activeThumb)"
        :prize="lotteryResultOrder"
      />
      <!-- 剩余抽奖机会 -->
      <div class="residue">
        <p>
          您还有
          <span v-text="remainChance"></span>次抽奖机会
        </p>
      </div>
      <!-- 我的奖品 -->
      <div class="my-prize" @click="onCheckMyPrize">我的奖品</div>
      <!-- 活动说明 -->
      <!-- <div class="desciption">
        <p class="title">活动说明</p>
        <ul class="des-list">
          <li class="des-item" v-for="(description, index) in descriptions" :key="index">{{ description }}</li>
        </ul>
      </div>-->
    </div>

    <!-- 弹窗：填写收货信息 -->
    <lottery-result-popup ref="refLotteryResultPopup" @confirm="onProcessLotteryResult" />

    <!-- 弹窗：选择卡包 -->
    <choose-pocket-popup ref="refChoosePocketPopup" @confirm="onConfirmPocket" />
  </div>
</template>

<script>
import {
  PRIZE_TYPE,
  PRIZE_ACTIVITYID
} from "@/pages/eleven2019/constant/lottery";
import ElevenNineGrid2019 from "../components/ElevenNineGrid2019.vue";
import ChoosePocketPopup from "../components/ChoosePocketPopup.vue";
import LotteryResultPopup from "../components/LotteryResultPopup.vue";

import moment from "moment";
import wx from "weixin-js-sdk";
const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const TEXT = {
  ACTIVITY_TIME_INVALID: "不在活动时间内",
  REMAIN_ZERO: "您的剩余抽奖次数为0"
};
// index值按照这样(逆时针)的顺序放进九宫格
// 映射：九宫格顺序(前端) => index(后端)
let PRIZE_ORDER;

export default {
  name: "eleven_lottery_2018",
  components: {
    "eleven-nine-grid": ElevenNineGrid2019,
    ChoosePocketPopup,
    LotteryResultPopup
  },
  data() {
    return {
      activityId: null, //活动的id
      bgimg: "", //背景图
      activity: {}, // 所在活动
      orderlyPrizeList: [], // 奖品列表
      remainChance: 0, // 剩余抽奖次数
      lotteryResult: {
        // 抽奖结果(结构需要与后端沟通，暂时为index + prizeId)
        index: null,
        prizeId: null
      },
      // 不同的活动配用不同的图片
      lotteryResultOrder: null, // 奖品(在九宫格中的)顺序
      lotteryBtn: {},
      descriptions: [
        "1、抽奖截止日期到2019年07月31日",
        "2、实体奖品统一于活动结束后发放",
        "3、抽中现金券需选择放入“白皙”或“汀”的下单系统使用",
        "4、现金券自领取日起有效为3个月，请尽快使用"
      ]
    };
  },
  methods: {
    /**
     * 将奖品列表按照一定顺序排列
     */
    getOrderlyPrizeList(prizes) {
      const retList = [];
      for (const index of PRIZE_ORDER) {
        const targetPrize = prizes.find(prize => prize.sortIndex === index);
        retList.push(targetPrize);
      }

      return retList;
    },
    /**
     * [事件]查看我的奖品
     */
    onCheckMyPrize() {
      this.$router.push({
        name: "eleven_prize_list_2019",
        query: {
          activityId: this.activityId
        }
      });
    },
    /**
     * 抽奖前，进行各种条件校验：
     * 返回true来启动抽奖，返回false将不能抽奖
     * 如果是没有userid的时候，就不操作
     */
    async beforeLottery() {
      // 重新请求
      await this.fetchLatestData();
      if (!sessionStorage.getItem("userId")) {
        return false;
      }
      // 检查时间范围
      if (!this.checkTime()) {
        await this.$messagebox.alert(TEXT.ACTIVITY_TIME_INVALID);
        return false;
      }

      // 检查剩余抽奖次数
      if (this.remainChance <= 0) {
        await this.$messagebox.alert(TEXT.REMAIN_ZERO);
        return false;
      }
      --this.remainChance;
      await this.postLottery();
      return true;
    },
    /**
     * 抽奖
     */
    async postLottery() {
      // 如果没有userid，则提示公众号抽奖
      if (!sessionStorage.getItem("userId")) {
        await this.$messagebox.alert("请从公众号进入抽奖");
        return;
      }
      const res = await this.$http1.get(`/elevenact/lottery`, {
        activityId: this.activityId,
        userId: sessionStorage.getItem("userId")
      });
      this.lotteryResult = res;
      console.log(this.lotteryResult);
      // 将index转化为九宫格中的顺序
      this.lotteryResultOrder = PRIZE_ORDER.findIndex(
        i => i === this.lotteryResult.index
      );
    },
    /**
     * 抽奖后，告知用户结果
     */
    afterLottery() {
      const { index, record } = this.lotteryResult;
      const prize = this.activity.prizes.find(
        prize => prize.sortIndex === index
      );
      this.$refs.refLotteryResultPopup.showPopup(record, prize);
    },
    /**
     * [事件]处理奖品
     */
    onProcessLotteryResult(record, prize) {
      switch (prize.type) {
        case PRIZE_TYPE.GOODS:
          this.$router.push({
            name: "address-list",
            query: {
              from: "eleven_lottery_2019",
              record
            }
          });
          break;
        case PRIZE_TYPE.COUPON:
          // this.$refs.refChoosePocketPopup.showPopup(record);
          break;
      }
    },
    /**
     * [事件]确认卡包后 => 关闭奖品结果弹窗
     */
    onConfirmPocket() {
      this.$refs.refLotteryResultPopup.closePopup();
    },
    /**
     * 检查活动是否在规定时间内
     * @returns {Boolean} true表示还能抽奖，false表示已过期
     */
    checkTime() {
      const now = moment();
      let { startDate, endDate } = this.activity;
      startDate = moment(startDate, DATETIME_FORMAT);
      endDate = moment(endDate, DATETIME_FORMAT);
      return now.isBetween(startDate, endDate);
    },
    /**
     * 请求最新数据
     */
    async fetchLatestData() {
      if (!sessionStorage.getItem("userId")) {
        await this.$messagebox.alert("请从公众号进入抽奖");
        return false;
      }
      const [resActivity, resChance] = await Promise.all([
        this.$http1.get(`/elevenact/activity`, { activityId: this.activityId }),
        this.$http1.get(`/elevenact/userInfo`, {
          activityId: this.activityId,
          userId: sessionStorage.getItem("userId")
        })
      ]);
      //不改变原来的节结构，拼接进去
      resActivity.activitiesMainEntity.prizes =
        resActivity.activitiesPrizesEntityList;
      this.activity = resActivity.activitiesMainEntity;
      this.remainChance = resChance.remain;
    }
  },
  // 渲染图片。背景图等
  mounted() {
    /*
     * (根据activityId)初始化本活动
     */
    wx.ready(() => {
     
      wx.hideMenuItems({
        menuList: [
          "menuItem:share:timeline",
          "menuItem:copyUrl",
          "menuItem:share:appMessage",
          "menuItem:share:qq",
          "menuItem:share:weiboApp",
          "menuItem:favorite",
          "menuItem:share:facebook",
          "menuItem:share:QZone",
          "menuItem:editTag",
          "menuItem:delete",
          "menuItem:copyUrl",
          "menuItem:originPage",
          "menuItem:readMode",
          "menuItem:openWithQQBrowser",
          "menuItem:openWithSafari",
          "menuItem:share:email",
          "menuItem:share:brand"
        ] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
      });
    });
    // 根据活动id去找到目录，渲染目录
    let dir = PRIZE_ACTIVITYID.find(element => {
      return element.id == this.activityId;
    });
    PRIZE_ORDER = dir["prize_list"];

    console.log("dir");
    console.log(dir);
    this.lotteryBtn = {
      img: require(`@/assets/images/eleven2019/${dir["dir"]}/click.png`),
      activeImg: require(`@/assets/images/eleven2019/${
        dir["dir"]
      }/click-active.png`)
    };
    this.bgimg = require(`@/assets/images/eleven2019/${dir["dir"]}/bg.png`);
  },
  async created() {
    this.activityId = this.$route.query.activityId;
    console.log(this.$route.query);
    if (!this.activityId) {
      // 出错
      this.$messagebox.alert("无法获取活动信息，请尝试刷新页面!");
      return;
    }

    await this.fetchLatestData();
    this.orderlyPrizeList = this.getOrderlyPrizeList(this.activity.prizes);
    if (!this.checkTime()) {
      await this.$messagebox.alert(TEXT.ACTIVITY_TIME_INVALID);
    }
  },
  beforeRouteLeave(to, from, next) {
    const refChoosePocketPopup = this.$refs.refChoosePocketPopup;
    if (refChoosePocketPopup.isShowing()) {
      refChoosePocketPopup.closePopup();
      next(false);
      return;
    }
    next({ activityId: this.activityId });
  },

  // 除去
  beforeRouteEnter(to, from, next) {
    console.log("跳转前");
    console.log(to.query);
    // 如果过去的页面又userid，则重定向去到
    if (to.query.userId) {
      sessionStorage.setItem("userId", to.query.userId);
      next({
        path: "/eleven2019/lottery",
        query: {
          activityId: to.query.activityId
        }
      });
    } else {
      next();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../less/size.less";
@import "../less/variable.less";
@import "../less/icon.less";

.eleven_lottery_2018 {
  user-select: none;
  font-family: PingFangSC-Regular;
  overflow: auto;

  .g-banner {
    position: relative;
    z-index: 1;
    .banner {
      width: 100%;
    }
  }
  .g-title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    margin-top: -170px;
    .title {
      width: 70%;
    }
  }
  .g-content {
    box-sizing: content-box;
    z-index: 0;
    // padding: 50px 0 20px;
    padding-top: 250px;

    .residue {
      height: 45px;
      line-height: 45px;
      text-align: center;
      font-size: 14px;
      span {
        -webkit-text-stroke: 1px #aaa;
        // text-shadow: 0px 0px 2px #000;
        font-size: 1.3rem;
        color: yellow;
        padding: 0 5px;
      }
    }

    .my-prize {
      width: 60%;
      height: 32px;
      line-height: 32px;
      margin: 0 auto;
      text-align: center;
      border-radius: 4px;
      font-size: 12px;
      background: white;
      color: #3b3b3b;
      margin-bottom: 30px;
    }

    .desciption {
      background: white;
      width: 85%;
      margin: 16px auto 20px;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;

      .title {
        flex-shrink: 0;
        color: white;
        background: #eb0000;
        border-radius: 14px;
        font-size: 12px;
        padding: 4px 10px;
        margin: 0 8px;
      }

      ul.des-list {
        flex-grow: 1;
        margin-right: 8px;
        list-style: none;
        font-size: 8px; // 字号要很小哦
        color: #ff6aa1;
        li.des-item {
          margin: 2px 0;
        }
      }
    }

    .bg {
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
}
.bgimg {
  position: absolute;
  width: 100vw;
  z-index: -1;
}
</style>
