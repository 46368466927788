<template>
  <!-- [弹窗]中奖结果 -->
  <mt-popup
    class="lottery-result-popup"
    v-model="localShow"
    position="center"
    pop-transition="popup-fade"
    :closeOnClickModal="false"
  >
    <!-- 内容部分 -->
    <div class="p-content">
      <!-- 说明 -->
      <p class="title" v-if="prize.type === PRIZE_TYPE.GOODS">恭喜您！获得以下实物奖品：</p>
      <p class="title" v-else>恭喜您！获得以下现金券：</p>
      <!-- 图片 -->
      <img class="prize-image" :src="prize.picture" />
      <!-- 名称 -->
      <p class="prize-name">{{ prize.name }}</p>
    </div>
    <!-- 按钮部分 -->
    <div class="btn" @click="onProcessPrize">
      <span v-if="prize.type === PRIZE_TYPE.GOODS" class="sign">+</span>
      <span class="text" v-if="prize.type === PRIZE_TYPE.GOODS">填写收货信息</span>
      <span class="text" v-else>我知道了</span>
    </div>
  </mt-popup>
</template>

<script>
import { PRIZE_TYPE } from "@/pages/eleven2019/constant/lottery";

export default {
  name: "lottery-result-popup",
  data() {
    return {
      PRIZE_TYPE,
      localShow: false, // 开关
      recordId: null, // 中奖纪录ID
      prize: {} // 所中奖品
    };
  },
  methods: {
    /**
     * 显示弹窗(并传入必要数据进行初始化)
     */
    showPopup(recordId, prize) {
      this.recordId = recordId;
      this.prize = prize;
      this.localShow = true;
    },
    /**
     * 关闭弹窗
     */
    closePopup() {
      // 先关掉
      this.localShow = false;
      // 再清空数据(如果顺序反了会造成视觉错误)
      // this.recordId = null
      // this.prize = {}
    },
    /**
     * [事件]点击按钮，对奖品进行处理
     */
    onProcessPrize() {
      this.localShow = false;
      this.$emit("confirm", this.recordId, this.prize);
    }
  }
};
</script>

<style lang="less" scoped>
.lottery-result-popup {
  color: #3b3b3b;
  background: transparent;

  .p-content {
    background: white;
    text-align: center;
    border-radius: 10px;
    width: 283px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .title {
      font-size: 14px;
      line-height: 20px;
      margin: 18px 0 0;
    }

    .prize-image {
      width: 143px;
      height: 104px;
      margin: 11px 0 0;
      object-fit: contain;
    }

    .prize-name {
      font-size: 12px;
      line-height: 17px;
      margin: 11px 0 19px;
    }
  }

  .btn {
    width: 226px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #f8d168;
    border-radius: 25px;
    margin: 20px auto 0;
    box-shadow: 0px 2px 10px -2px white;

    .sign {
      font-size: 18px;
    }

    .text {
      font-size: 15px;
    }
  }
}
</style>
