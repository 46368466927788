<template>
  <!-- 弹窗：选择卡包 -->
  <mt-popup
    class="choose-pocket-popup"
    position="bottom"
    v-model="localShow"
    pop-transition="popup-fade"
  >
    <p class="title">请选择放入卡包</p>
    <ul class="brand-list">
      <li
        class="brand-item"
        v-for="(brand, index) in brands"
        :key="brand.id"
        @click="onSelectBrand(brand, index, brands)"
      >
        <span class="name">{{ brand.name }}</span>
        <img class="icon" v-if="selected[brand.id]" src="@/assets/images/eleven2019/checked.png" />
        <img class="icon" v-else src="@/assets/images/eleven2019/unselected.png" />
      </li>
    </ul>
    <button class="confirm" @click="onConfirm">确定</button>
  </mt-popup>
</template>

<script>
/**
 * 非UI组件(与业务相关，不考虑可重用性)
 */
export default {
  name: "choose-pocket-popup",
  model: {
    prop: "show",
    event: "change"
  },
  props: {
    show: {
      // 控制
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localShow: false, // 开关
      selected: {}, // 选中与否
      brands: [], // 品牌数组
      recordId: null // (需要关联的)奖品记录ID
    };
  },
  watch: {
    show() {
      this.localShow = this.show;
    },
    localShow() {
      this.$emit("change", this.localShow);
    }
  },
  methods: {
    /**
     * 辅助方法：选中某个brandID
     */
    _select(brandId) {
      for (const brand of this.brands) {
        this.$set(this.selected, brand.id, false);
      }
      this.selected[brandId] = true;
    },
    /**
     * 事件：点击item
     */
    onSelectBrand(brand, index, brands) {
      this._select(brand.id);
    },
    /**
     * 事件：点击确定
     */
    async onConfirm() {
      // 调接口
      const targetBrand = this.brands.find(brand => this.selected[brand.id]); // 选中的品牌
      await this.$http.put(`lottery/record/${this.recordId}`, {
        brandId: targetBrand.id
      });
      // 然后关闭自己
      this.closePopup();
      // await this.$messagebox.alert(`已放入${targetBrand.name}卡包`)

      this.$emit("confirm");
    },
    /**
     * 展示
     */
    showPopup(recordId) {
      this.recordId = recordId;
      this.localShow = true;
    },
    /**
     * 关闭
     */
    closePopup() {
      this.localShow = false;
      this.recordId = null;
    },
    /**
     * 是否正在打开
     */
    isShowing() {
      return this.localShow;
    }
  },
  async created() {
    // const data = await this.$http.get(`brand/name`);
    // this.brands = data;
    // this._select(this.brands[0].id); // 默认选中第一个
  }
};
</script>

<style lang="less" scoped>
.choose-pocket-popup {
  background: white;
  width: 100vw;
  font-size: 15px;
  line-height: 21px;

  .title {
    margin: 19px 0 16px;
    text-align: center;
  }

  .brand-list {
    padding-bottom: 10px;

    .brand-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 14px 18px 14px 16px;

      .icon {
        width: 18px;
        height: 18px;
      }
    }
  }

  .confirm {
    background-color: #f8d168;
    height: 50px;
    width: 100%;
    font-size: 15px;
    color: #3b3b3b;
    outline: none;
    border: transparent;
  }
}
</style>
